import React from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from '../components/Layout/Layout';
import {
  Link,
  Box,
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import COLORS from '../styles/colors';
import { Zap, Grid as GridIcon, Gift } from 'react-feather';
import Discount from '../components/Icon/Discount';
import useWindowSize from '../hooks/useWindowSize';
import {
  URL_DOWNLOAD_APP_STORE,
  URL_DOWNLOAD_PLAY_STORE,
} from '../constants/url';

const keunggulan1 = [
  {
    id: 1,
    icon: <Zap size={21} />,
    title: 'Akses mudah hanya dengan handphone',
    description:
      'Kemudahan belanja ada di dalam genggaman Anda. Kamu bisa mengakses aplikasi Oxone Online kapan dan dimana saja.',
  },
  {
    id: 2,
    icon: <GridIcon size={21} />,
    title: 'Banyak pilihan produk',
    description:
      'Produk kami bervariasi mulai dari Oven, Microwave, Cookware Set, Dispenser hingga Vacuum Cleaner dan Air Cooler.',
  },
];
const keunggulan2 = [
  {
    id: 3,
    icon: <Gift size={21} />,
    title: 'Dapatkan keuntungan komisi',
    description:
      'Dapatkan keuntungan komisi untuk setiap transaksi di aplikasi Oxone Online*.',
    notes: '*) Komisi berlaku untuk member Premium ',
  },
  {
    id: 3,
    icon: <Discount size={21} />,
    title: 'Banyak diskon menarik',
    description:
      'Temukan beragam diskon dan promo menarik ketika belanja produk Oxone.',
  },
];

export default function LandingPage() {
  const windowSize = useWindowSize();
  const classes = useStyles();

  const _renderKeunggulanItem = (item: any) => {
    return (
      <div style={{ marginTop: 80, marginBottom: 50 }}>
        <Box
          width={40}
          height={40}
          style={{
            borderRadius: 10,
            padding: 9,
            backgroundColor: COLORS.warn,
            textAlign: 'center',
            marginBottom: 10,
          }}
        >
          {item.icon}
        </Box>

        <Typography
          variant="h4"
          style={{ fontWeight: 'bold', marginBottom: 10 }}
        >
          {item.title}
        </Typography>

        <Typography
          variant="body1"
          style={{
            marginBottom: 10,
          }}
        >
          {item.description}
        </Typography>

        {item.notes ? (
          <Typography
            variant="subtitle1"
            style={{
              marginBottom: 10,
            }}
          >
            {item.notes}
          </Typography>
        ) : (
          false
        )}
      </div>
    );
  };

  return (
    <Layout title="Download">
      <div className={classes.bgContainerTop}>
        <Container maxWidth="lg" className={classes.containerTop}>
          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              md={5}
              lg={5}
              style={{
                paddingLeft:
                  windowSize === 'sm' ||
                  windowSize === 'md' ||
                  windowSize === 'lg'
                    ? 20
                    : 100,
                marginTop: 50,
              }}
            >
              <Typography
                variant="h5"
                style={{ color: COLORS.warn, marginBottom: 10 }}
              >
                Be a Chef at Your Own Home
              </Typography>

              <Typography variant="h2" style={{ fontWeight: 'bold' }}>
                Dapatkan Beragam Manfaat dengan Aplikasi Oxone Online
              </Typography>

              <Typography variant="body1" style={{ marginTop: 20 }}>
                Dapatkan semua kemudahan belanja produk terbaik Oxone dengan
                mudah, nikmati penawaran menarik dari Oxone dan dapatkan komisi
                setiap kali kamu Belanja
              </Typography>

              <Box display="flex" flexDirection="row" style={{ marginTop: 30 }}>
                <Box style={{ marginRight: 10 }}>
                  <Link href={URL_DOWNLOAD_APP_STORE}>
                    <img
                      alt="Download Aplikasi Oxone"
                      src="assets/app-store-download.png"
                      style={{ width: 150 }}
                    />
                  </Link>
                </Box>
                <Box style={{ marginRight: 10 }}>
                  <Link href={URL_DOWNLOAD_PLAY_STORE}>
                    <img
                      alt="Download Aplikasi Oxone"
                      src="assets/play-store-download.png"
                      style={{ width: 150 }}
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={1} lg={1} />
            <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'center' }}>
              <img
                alt="Download Aplikasi Oxone"
                src={
                  windowSize === 'sm' || windowSize === 'md'
                    ? 'assets/iPhone-12-Pro.png'
                    : 'assets/download-top.png'
                }
                style={{
                  width:
                    windowSize === 'sm' || windowSize === 'md' ? '100%' : '70%',
                  transform:
                    windowSize === 'xlg' || windowSize === 'lg'
                      ? 'translateX(70px)'
                      : 'translateX(30px)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/** BEGIN KEUNGGULAN */}
      <div>
        <Container maxWidth="lg">
          <Grid
            container
            direction="row-reverse"
            spacing={5}
            style={{ paddingTop: 50, paddingBottom: 50 }}
          >
            <Grid item xs={12} md={4} lg={4} />
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="h2" style={{ fontWeight: 'bold' }}>
                Kenapa Harus Belanja dengan Oxone Online?
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              {keunggulan1.map((item) => _renderKeunggulanItem(item))}
            </Grid>
            <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'center' }}>
              <img
                alt="Download Oxone Online"
                src="assets/download-1.png"
                style={{ width: '70%' }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={classes.bgContainerTop}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={5}
            style={{ paddingTop: 50, paddingBottom: 50 }}
          >
            <Grid item xs={12} md={6} lg={6}>
              {keunggulan2.map((item) => _renderKeunggulanItem(item))}
            </Grid>
            <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'center' }}>
              <img
                alt="Download Aplikasi Oxone"
                src="assets/download-2.png"
                style={{ width: '70%' }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      {/** END KEUNGGULAN */}

      <div className={classes.bgBlack}>
        <img
          alt="Download Oxone Online"
          src="assets/background/bg-1.png"
          style={{ width: '100%' }}
        />
      </div>

      <div className={classes.footerDownload}>
        <Typography
          variant="h3"
          style={{ fontWeight: 'bold', color: COLORS.accent }}
        >
          Download Oxone Online Sekarang!
        </Typography>

        <Box
          display="flex"
          flexDirection={
            windowSize && ['sm', 'md'].includes(windowSize) ? 'column' : 'row'
          }
          justifyContent="center"
          style={{ marginTop: 10 }}
        >
          <Box style={{ marginRight: 10 }}>
            <Link href={URL_DOWNLOAD_APP_STORE}>
              <img
                alt="Download Aplikasi Oxone"
                src="assets/app-store-download.png"
                style={{ width: 200 }}
              />
            </Link>
          </Box>
          <Box style={{ marginRight: 10 }}>
            <Link href={URL_DOWNLOAD_PLAY_STORE}>
              <img
                alt="Download Aplikasi Oxone"
                src="assets/play-store-download.png"
                style={{ width: 200 }}
              />
            </Link>
          </Box>
        </Box>
      </div>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bgContainerTop: {
      padding: theme.spacing(5, 0, 0, 0),
      backgroundColor: COLORS.greyLighten,
    },
    containerTop: {},
    footerDownload: {
      textAlign: 'center',
      padding: theme.spacing(15, 2, 15, 2),
      backgroundColor: COLORS.primary,
    },
    bgBlack: {
      backgroundColor: COLORS.primary,
    },
  })
);
