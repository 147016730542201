import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from '../screens/LandingPage';
import RequestDeleteAccountPage from '../screens/RequestDeleteAccountPage';

const RootNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/request-delete-account"
          element={<RequestDeleteAccountPage />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RootNavigation;
