export const howToDelete = [
  {
    title: 'Langkah 1: Isi Formulir Penghapusan Akun',
    steps: [
      'Kunjungi tautan berikut {form_url} untuk membuka formulir penghapusan akun OPA.',
      'Isi formulir dengan informasi yang akurat sesuai dengan informasi akun Anda. Informasi yang Anda berikan harus cocok dengan data yang terkait dengan data yang sudah Anda masukkan ke Aplikasi Oxone Premium App.',
      'Kemudian submit formulir yang sudah Anda isi.',
    ],
  },
  {
    title: 'Langkah 2: Validasi Data',
    steps: [
      'Setelah submit formulir, Customer Service kami akan melakukan validasi informasi yang Anda berikan',
      'Jika data cocok dengan data yang tersimpan pada sistem maka Customer Service akan menghubungi Anda melalui Whatsapp untuk memastikan bahwa benar Anda ingin melakukan penghapusan akun',
      'Jika saat proses validasi data yang dimasukkan dengan data sistem atau saat Customer Service menghubungi Anda kemudian disimpulkan bahwa data tidak valid maka akun Anda tidak akan dihapus dan permintaan penghapusan akun akan kami hapus',
    ],
  },
  {
    title: 'Langkah 3: Proses Penghapusan Akun',
    steps: [
      'Setelah informasi dapat divalidasi, pihak Oxone akan melakukan proses penghapusan akun. Proses ini memakan waktu maksimal 1x24 Jam.',
      'Data akun yang akan di hapus yaitu: semua informasi pribadi, nomor handphone, kata sandi, dan daftar transaksi',
      'Data yang disebutkan pada point 2. akan dihapus secara permanen dan tidak dapat dipulihkan',
      'Setelah data akun dihapus maka ketika Anda masuk ke aplikasi dengan nomor handphone yang sama, Anda akan diminta untuk mengisi data registrasi dari awal',
      'Ketika proses penghapusan selesai semua informasi yang Anda masukkan pada formulir penghapusan akun juga akan kami hapus',
    ],
  },
];

export const CUSTOMER_HELP_PHONE_NUMBER = '0877-5200-1881';
export const CUSTOMER_HELP_URL_WHATSAPP = 'https://wa.me/6287752001881';
export const CUSTOMER_HELP_EMAIL = 'cs@oxone-online.com';
export const LINK_GOOGLE_FORM = 'https://forms.gle/6PmiXVtp1Gs9vqUq5';
