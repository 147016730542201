import styled from 'styled-components';
import {
  CUSTOMER_HELP_EMAIL,
  CUSTOMER_HELP_PHONE_NUMBER,
  CUSTOMER_HELP_URL_WHATSAPP,
  LINK_GOOGLE_FORM,
  howToDelete,
} from '../constants/how-to-delete-account';
import Layout from '../components/Layout/Layout';

const RequestDeleteAccountPage = () => {
  function _renderStep(step: string) {
    let urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/g;
    const result = step
      .replace(/{form_url}/g, LINK_GOOGLE_FORM)
      .replace(
        urlPattern,
        (url) =>
          `<a href="${url}" target="_blank" rel="noreferrer">"Link Formulir Hapus Akun"</a>`
      );

    return {
      __html: result,
    };
  }

  return (
    <Layout title="Download">
      <ContainerPage>
        <TitlePage>
          Proses Penghapusan Akun di Aplikasi Oxone Premium App
        </TitlePage>
        <SubTitlePage>
          Menghapus akun Anda dari Aplikasi Oxone Premium App memerlukan
          beberapa langkah untuk memastikan data Anda benar dan akurat. Silahkan
          ikuti langkah-langkah di bawah ini untuk menghapus akun Anda:
        </SubTitlePage>
        <Content>
          {howToDelete.map((item, idx) => (
            <Item key={idx}>
              <p>{item.title}</p>
              <div>
                {item.steps.map((step, idx) => (
                  <ItemPoint key={idx}>
                    <span>{idx + 1}</span>
                    <div dangerouslySetInnerHTML={_renderStep(step)}></div>
                  </ItemPoint>
                ))}
              </div>
            </Item>
          ))}
        </Content>
        <SubTitlePage>
          Perlu diingat, menghapus akun Anda bersifat permanen dan Anda tidak
          akan dapat memulihkan data atau mengakses akun setelah dihapus.
          Pastikan Anda yakin tentang keputusan Anda sebelum melanjutkan dengan
          proses penghapusan akun.
        </SubTitlePage>

        <Helper style={{ marginTop: 64 }}>
          <span>Official Phone Number</span>
          <div>{CUSTOMER_HELP_PHONE_NUMBER}</div>
        </Helper>
        <Helper>
          <span>Official Whatsapp Number</span>
          <a href={CUSTOMER_HELP_URL_WHATSAPP} target="_blank" rel="noreferrer">
            {CUSTOMER_HELP_PHONE_NUMBER}
          </a>
        </Helper>
        <Helper>
          <span>Oxone Customer Support</span>
          <div>{CUSTOMER_HELP_EMAIL}</div>
        </Helper>
      </ContainerPage>
    </Layout>
  );
};

const Helper = styled.div`
  text-align: center;
  margin: 24px 0;

  span {
    display: block;
    opacity: 0.5;
  }

  a {
    font-size: 16px !important;
  }
`;

const ContainerPage = styled.div`
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
`;

const TitlePage = styled.p`
  font-size: 24px;
  text-align: center;
  font-weight: bold;
`;

const SubTitlePage = styled.p`
  opacity: 0.6;
  margin-top: 0px;
  text-align: center;
`;

const Content = styled.div`
  padding: 20px;
  min-height: 100vh;
  margin-top: 42px;
`;

const Item = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;

  p {
    flex: 1;
    margin: 0;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 16px;
  }
`;

const ItemPoint = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  div {
    flex: 1;
  }
  span {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1000px;
    margin-right: 10px;
  }
`;

export default RequestDeleteAccountPage;
