import React from 'react';
import { IconProps } from './IconProps';

export default function LogoOxone(props: IconProps) {
  return (
    <svg
      width={props.size || 81}
      height={props.size || 32}
      viewBox="0 0 81 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M37.1288 16.9281H30.0559L27.7192 20.1065L24.9069 16.7182H17.8349L23.943 23.8426L17.4229 31.2422H24.7682L27.1698 27.715L30.1221 31.2422H37.4014L31.1548 23.7037L37.1288 16.9281Z"
          fill="#101010"
        />
        <path
          d="M42.6545 16.2362C38.4257 16.2362 34.9962 19.6885 34.9962 23.9476C34.9962 28.2067 38.4257 31.6581 42.6545 31.6581C46.8833 31.6581 50.3112 28.2041 50.3112 23.9476C50.3112 19.691 46.8819 16.2362 42.6545 16.2362ZM42.6545 27.661C40.6166 27.661 38.9643 25.9995 38.9643 23.9476C38.9643 21.8957 40.6166 20.2293 42.6545 20.2293C44.6924 20.2293 46.341 21.8948 46.341 23.9476C46.341 26.0004 44.6911 27.6603 42.6545 27.6603V27.661Z"
          fill="#101010"
        />
        <path
          d="M64.1734 18.0134C63.7802 17.4915 63.2081 17.0719 62.4767 16.7527C61.7411 16.4312 60.931 16.2722 60.0586 16.2732C59.0628 16.2732 58.1401 16.4867 57.3071 16.9178C56.7021 17.2329 56.1451 17.6581 55.6362 18.1935V16.5995H51.493V31.676H55.9061V24.8163C55.9061 24.0156 55.929 23.3431 55.976 22.8037C56.023 22.2643 56.0969 21.8548 56.1824 21.5914C56.2633 21.3333 56.3869 21.0907 56.5481 20.8737C56.7112 20.6532 56.9112 20.4627 57.1392 20.3106C57.6104 19.982 58.1316 19.8194 58.7319 19.8194C59.1969 19.8194 59.5679 19.9342 59.8721 20.1457C60.1835 20.3625 60.4041 20.6579 60.5481 21.0627C60.5918 21.1902 60.6232 21.3215 60.6419 21.455C60.693 21.7946 60.7258 22.1367 60.74 22.4799C60.7629 22.9011 60.7744 23.4061 60.7744 23.9873V31.676H65.1889V22.3791C65.1889 21.2524 65.1202 20.3768 64.9723 19.7381C64.8205 19.0997 64.557 18.5198 64.1734 18.0134Z"
          fill="#101010"
        />
        <path
          d="M80.0603 21.187C79.7535 20.1255 79.2917 19.2287 78.6687 18.5088C78.0397 17.779 77.2511 17.2043 76.3641 16.8296C75.48 16.4546 74.4816 16.2732 73.3806 16.2732C72.3946 16.2732 71.4821 16.4477 70.6555 16.8034C69.8267 17.1641 69.0877 17.6987 68.4385 18.4071C67.7841 19.1174 67.2945 19.9687 66.9728 20.9426C66.6511 21.9166 66.4897 23.0205 66.4897 24.2459C66.4897 25.2715 66.6112 26.2183 66.8591 27.0804C67.1028 27.9414 67.4722 28.7218 67.9684 29.4098C68.5842 30.2616 69.4151 30.9341 70.376 31.3584C71.347 31.7887 72.477 32 73.7594 32C74.5704 32 75.3234 31.9063 76.0064 31.7164C76.6958 31.5256 77.3184 31.2366 77.874 30.8494C78.9812 30.0787 79.7913 28.9542 80.2935 27.5048L80.4129 27.1604L75.997 26.4122L75.9191 26.6776C75.823 27.0221 75.7044 27.3096 75.5662 27.5457C75.4279 27.7818 75.2748 27.9635 75.1044 28.101C74.9318 28.2402 74.7351 28.3462 74.5241 28.4138C74.2897 28.4877 74.045 28.5238 73.7993 28.5206C73.4001 28.5206 73.0426 28.4485 72.717 28.3095C72.396 28.1697 72.1046 27.9607 71.8359 27.6716C71.5731 27.3905 71.3718 27.0515 71.2321 26.6492C71.1205 26.324 71.0487 25.9602 71.0175 25.5508H80.5088L80.5122 25.2451C80.5159 25.1215 80.5159 24.9945 80.5159 24.8751C80.516 23.4773 80.3642 22.2479 80.0603 21.187ZM71.2512 21.5732C71.363 21.2248 71.5451 20.9032 71.7863 20.6282C72.011 20.37 72.2879 20.1627 72.5987 20.0199C72.9007 19.8883 73.2336 19.8194 73.6076 19.8194C73.9351 19.8153 74.2599 19.88 74.561 20.0093C74.8499 20.1363 75.1129 20.326 75.3578 20.5869C75.5926 20.8381 75.7808 21.1502 75.9081 21.5379C76.0177 21.8775 76.0848 22.2294 76.1078 22.5855H71.076C71.095 22.2107 71.1528 21.8713 71.2512 21.5732Z"
          fill="#101010"
        />
        <path
          d="M20.9542 22.0408C19.4473 23.3425 17.5228 24.0575 15.5328 24.0548C10.9252 24.0548 7.18666 20.2916 7.18666 15.6471C7.18666 11.0027 10.9241 7.23854 15.5328 7.23854C20.1416 7.23854 23.8813 11.0027 23.8813 15.6471H25.352L27.5488 18.2422L29.5384 15.6818L31.0684 15.6474C31.0689 7.00705 24.1122 0 15.5328 0C6.95347 0 0 7.00705 0 15.6471C0 24.2872 6.95485 31.2929 15.5328 31.2929L16.0831 31.1719L22.6723 23.9113L20.9542 22.0408Z"
          fill="#101010"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="80.5161" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
