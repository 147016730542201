import { Box, Container, CssBaseline, Toolbar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect } from 'react';
import Copyright from '../Copyright';
import COLORS from '../../styles/colors';
import useWindowSize from '../../hooks/useWindowSize';
import LogoOxone from '../Icon/LogoOxone';

type IProps = {
  title?: string;
  children?: any;
};

export default function Layout(props: IProps) {
  const classes = useStyles();

  const windowSize = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      if (windowSize === 'sm' || windowSize === 'md') {
        handleDrawerClose();
      } else {
        handleDrawerOpen();
      }
    }, 300);
  }, [windowSize]);

  const [, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <main className={classes.content}>
        <div className={classes.bgContainerTop}>
          <Container maxWidth="lg">
            <AppBar
              position="relative"
              elevation={0}
              className={classes.appBar}
            >
              <Toolbar
                disableGutters={true}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LogoOxone />
              </Toolbar>
            </AppBar>
          </Container>
        </div>
        {props.children}
        <Box py={1} className={classes.copyright}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbarHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  bgContainerTop: {
    backgroundColor: COLORS.greyLighten,
  },
  appBar: {
    backgroundColor: COLORS.greyLighten,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  dividerVertical: {
    backgroundColor: COLORS.greyLight,
    margin: 10,
  },
  copyright: {
    textAlign: 'center',
    padding: theme.spacing(3, 0, 3, 0),
    backgroundColor: COLORS.primary,
  },
}));
