import { Typography, Link } from '@material-ui/core';
import React from 'react';
import COLORS from '../styles/colors';

type Props = {
  color?:
    | 'inherit'
    | 'initial'
    | 'textSecondary'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'error'
    | undefined;
};

export default function Copyright(props: Props) {
  return (
    <Typography
      variant="subtitle2"
      align="center"
      style={{ color: COLORS.accent }}
    >
      {'©'}
      {new Date().getFullYear()}{' '}
      <Link color="inherit" href="/">
        Oxone
      </Link>{' '}
      All rights reserved
    </Typography>
  );
}
